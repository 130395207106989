$blue: #0d6efd;
$blue-500: $blue;
$blue-100: mix(white, $blue, 80);
$blue-200: mix(white, $blue, 60);
$blue-300: mix(white, $blue, 40);
$blue-400: mix(white, $blue, 20);
$blue-600: mix(black, $blue, 20);
$blue-700: mix(black, $blue, 40);
$blue-800: mix(black, $blue, 60);
$blue-900: mix(black, $blue, 80);

$indigo: #6610f2;
$indigo-500: $indigo;
$indigo-100: mix(white, $indigo, 80);
$indigo-200: mix(white, $indigo, 60);
$indigo-300: mix(white, $indigo, 40);
$indigo-400: mix(white, $indigo, 20);
$indigo-600: mix(black, $indigo, 20);
$indigo-700: mix(black, $indigo, 40);
$indigo-800: mix(black, $indigo, 60);
$indigo-900: mix(black, $indigo, 80);

$purple: #6f42c1;
$purple-500: $purple;
$purple-100: mix(white, $purple, 80);
$purple-200: mix(white, $purple, 60);
$purple-300: mix(white, $purple, 40);
$purple-400: mix(white, $purple, 20);
$purple-600: mix(black, $purple, 20);
$purple-700: mix(black, $purple, 40);
$purple-800: mix(black, $purple, 60);
$purple-900: mix(black, $purple, 80);

$pink: #d63384;
$pink-500: $pink;
$pink-100: mix(white, $pink, 80);
$pink-200: mix(white, $pink, 60);
$pink-300: mix(white, $pink, 40);
$pink-400: mix(white, $pink, 20);
$pink-600: mix(black, $pink, 20);
$pink-700: mix(black, $pink, 40);
$pink-800: mix(black, $pink, 60);
$pink-900: mix(black, $pink, 80);

$red: #dc3545;
$red-500: $red;
$red-100: mix(white, $red, 80);
$red-200: mix(white, $red, 60);
$red-300: mix(white, $red, 40);
$red-400: mix(white, $red, 20);
$red-600: mix(black, $red, 20);
$red-700: mix(black, $red, 40);
$red-800: mix(black, $red, 60);
$red-900: mix(black, $red, 80);

$orange: #fd7e14;
$orange-500: $orange;
$orange-100: mix(white, $orange, 80);
$orange-200: mix(white, $orange, 60);
$orange-300: mix(white, $orange, 40);
$orange-400: mix(white, $orange, 20);
$orange-600: mix(black, $orange, 20);
$orange-700: mix(black, $orange, 40);
$orange-800: mix(black, $orange, 60);
$orange-900: mix(black, $orange, 80);

$yellow: #ffc107;
$yellow-500: $yellow;
$yellow-100: mix(white, $yellow, 80);
$yellow-200: mix(white, $yellow, 60);
$yellow-300: mix(white, $yellow, 40);
$yellow-400: mix(white, $yellow, 20);
$yellow-600: mix(black, $yellow, 20);
$yellow-700: mix(black, $yellow, 40);
$yellow-800: mix(black, $yellow, 60);
$yellow-900: mix(black, $yellow, 80);

$green: #198754;
$green-500: $green;
$green-100: mix(white, $green, 80);
$green-200: mix(white, $green, 60);
$green-300: mix(white, $green, 40);
$green-400: mix(white, $green, 20);
$green-600: mix(black, $green, 20);
$green-700: mix(black, $green, 40);
$green-800: mix(black, $green, 60);
$green-900: mix(black, $green, 80);

$teal: #20c997;
$teal-500: $teal;
$teal-100: mix(white, $teal, 80);
$teal-200: mix(white, $teal, 60);
$teal-300: mix(white, $teal, 40);
$teal-400: mix(white, $teal, 20);
$teal-600: mix(black, $teal, 20);
$teal-700: mix(black, $teal, 40);
$teal-800: mix(black, $teal, 60);
$teal-900: mix(black, $teal, 80);

$cyan: #0dcaf0;
$cyan-500: $cyan;
$cyan-100: mix(white, $cyan, 80);
$cyan-200: mix(white, $cyan, 60);
$cyan-300: mix(white, $cyan, 40);
$cyan-400: mix(white, $cyan, 20);
$cyan-600: mix(black, $cyan, 20);
$cyan-700: mix(black, $cyan, 40);
$cyan-800: mix(black, $cyan, 60);
$cyan-900: mix(black, $cyan, 80);

$gray-500: #adb5bd;
