.resize-handle {
    position: absolute;
    top: 35px;
    right: 0;
    background: black;
    opacity: 0;
    width: 3px;
    cursor: col-resize;
    height: 30px;
}

.resize-handle:hover {
    opacity: 0.5;
}

.resize-handle-selected {
    opacity: 0.5;
}

.table-scroll {
    overflow: 'scroll';
    max-height: calc(100vh - 400px);
}

.thead-sticky {
    position: 'sticky';
    top: 0;
    z-index: 10;
}
