@keyframes infoShowIn {
    to {
        width: 7rem;
        border-top-right-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
    }
}

.btn-group {
    position: relative;
    padding-left: 1.7rem;

    .btn.status-check {
        position: absolute !important;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 1;
        overflow: hidden;
        padding-left: 0.3rem;
        width: 1.7rem;
        text-align: left;
        white-space: nowrap;
        cursor: default !important;

        &:hover {
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
            animation-name: infoShowIn;
            animation-delay: 0.5s;
        }

        &::after {
            margin-left: 0.5rem;
        }

        &.btn-success {
            &::after {
                content: 'Ready';
            }
        }

        &.btn-danger {
            padding-left: 0.5rem;
            &::after {
                content: 'Not Ready';
            }
        }

        &.btn-secondary {
            &::after {
                content: 'Published';
            }
        }

        &.btn-warning {
            padding-left: 0.5rem;
            &::after {
                content: 'Error';
            }
        }
    }

    .publish-button {
        padding-left: 1.4rem;
        padding-right: 1.4rem;
    }
}

td {
    position: relative;

    .publishing-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #fff, $alpha: 0.8);
        z-index: 1;
    }
}
