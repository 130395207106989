ul.vehicle-list {
    padding: 0;
    margin: 0;
    white-space: nowrap;
    list-style-type: none;

    .highlight-vehicle {
        font-weight: bold;
    }
}
