$oemiq-dark-blue: #095792;
$oemiq-light-blue: #0094ff;
$oemiq-dark-grey: #4d4d4e;

//Overridden Bootstrap variables. Bootstrap has many more variables we can override. Look in the _variables.scss for more.
$gray-300: #dee2e6;

$primary: $oemiq-dark-blue;

//breadcrumb variables
$breadcrumb-padding-y: inherit;
$breadcrumb-padding-x: inherit;
$breadcrumb-margin-bottom: 0px;
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: white;
$breadcrumb-active-color: $gray-300;

//borders
$border-width: 1px;
$border-color: $gray-300;

@import './css//cursor.scss';
@import './css/positioning.scss';
@import './css/whitespace.scss';
@import './css/date.scss';
@import './css/switches.scss';
@import './css/border.scss';
@import './css/sizing.scss';
@import './css/opacity.scss';
@import 'node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/oemiq-common/styles/package-theme.scss';
@import './css/procedures.scss';
@import './css/procedures.scss';
@import './css/_base.scss';
@import './css/select.scss';
@import './css/common.scss';
@import './css/fonts.scss';
