.clickable {
    cursor: pointer;
}

.grabbable {
    cursor: grab;
}

.pe-none {
    pointer-events: none;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
    pointer-events: inherit !important;
}

.cursor-not-allowed svg {
    pointer-events: none;
}
