.ddl-clearable-btn {
    position: absolute;
    top: 50%;
    right: 35px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}
