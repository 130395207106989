.multi-user {
    height: 60vh;
}

.error-cell {
    color: #dc3545;
}

.multi-user-modal {
    .MuiDataGrid-cell--editing.MuiDataGrid-cell.MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editable.MuiDataGrid-withBorderColor {
        padding: 0px !important;
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: rgba(224, 224, 224, 1) !important;
        border-top: none !important;
        border-bottom: none !important;
    }
    .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
            border-color: transparent !important;
        }
    }
    .MuiDataGrid-row--editing {
        box-shadow: none !important;
    }
    input.MuiInputBase-input {
        padding: 0px 10px !important;
    }
    .MuiDataGrid-row.MuiDataGrid-row--editable {
        min-height: 40.667px !important;
        background-color: unset !important;
    }
    .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor {
        height: 40.667px !important;
    }
    .MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiDataGrid-editInputCell {
        padding: 0 !important;
    }
    p.MuiTypography-root.MuiTypography-body1.input-placeholder {
        line-height: 40px !important;
    }
    .MuiDataGrid-cellContent {
        font-size: 14px !important;
    }
    .MuiButtonBase-root.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium {
        margin: 0 !important;
    }
    .MuiDataGrid-columnHeadersInner.MuiDataGrid-columnHeadersInner--scrollable {
        height: 40.667px !important;
    }
    .MuiDataGrid-cell.MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editable.MuiDataGrid-withBorderColor {
        gap: 5px;
    }
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl {
        gap: 5px;
    }
}

@media (min-width: 1200px) {
    .multi-user-modal.modal-dialog {
        width: 80%;
        min-width: unset;
        max-width: unset;
    }
}

.chevron-top-aligned {
    .MuiInputBase-root {
        border-radius: 0px;
        .MuiAutocomplete-endAdornment {
            position: absolute;
            right: 0;
            top: calc(100% - (calc(100% - 30px)));
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
        }
    }
}

.auto-complete-height-100 {
    height: 100%;
    .MuiFormControl-root {
        height: 100%;
        .MuiInputBase-root {
            height: 100%;
        }
    }
}

.disabled-chip.Mui-disabled {
    opacity: 0.6;
    pointer-events: none;
}

//Multi-User
.multi-user {
    .MuiDataGrid-root {
        font-family: inherit !important;
    }

    .MuiDataGrid-row.Mui-selected,
    .MuiDataGrid-row.Mui-hovered {
        background-color: unset !important;
    }

    .MuiDataGrid-row .MuiOutlinedInput-root {
        padding: 0px 10px !important;
    }

    .MuiDataGrid-row .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within {
        border: 0.666667px solid #84abc9;
        outline: 0;
    }

    .chevron-top-aligned .MuiInputBase-root .MuiAutocomplete-endAdornment {
        top: 50% !important;
    }

    .MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator {
        visibility: hidden !important;
    }
    .MuiDataGrid-columnHeaders {
        pointer-events: none !important;
        user-select: none !important;
        min-height: unset !important;
    }

    .MuiInputBase-input {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
            'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 400 !important;
        font-size: 14px !important;
        color: #212529 !important;
        line-height: 1.5;
    }

    .input-placeholder {
        opacity: 2;
        font-size: 14px;
    }
    .MuiInputBase-input::placeholder {
        color: #6c757d !important;
        opacity: 2;
        font-size: 14px;
    }

    .MuiAutocomplete-popupIndicator.MuiAutocomplete-popupIndicatorOpen {
        rotate: unset !important;
    }

    .select-IndicatorsContainer {
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
        align-self: stretch;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        box-sizing: border-box;
    }

    .select-indicatorSeparator {
        -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
        align-self: stretch;
        background-color: hsl(0, 0%, 80%);
        margin-bottom: 8px;
        margin-top: 8px;
        width: 1px;
        box-sizing: border-box;
    }

    .select-indicatorContainer {
        color: hsl(0, 0%, 80%);
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding: 8px;
        -webkit-transition: color 150ms;
        transition: color 150ms;
        box-sizing: border-box;
    }

    .select-Svg {
        display: inline-block;
        fill: currentColor;
        line-height: 1;
        stroke: currentColor;
        stroke-width: 0;
    }

    .MuiAutocomplete-popupIndicator.MuiAutocomplete-popupIndicatorOpen {
        transform: none !important;
    }
}
//END
.text-danger-multi-user {
    color: #dc3545;
}
