.cb-dropdown {
    padding: 0px 10px;
    position: relative;
    .cb-dropdown-trigger {
        color: #b7bec4;
    }
    .cb-dropdown-menu {
        display: inline-flex;
        flex-direction: column;
        text-transform: none;
        padding: 20px 20px;
        justify-content: left;
        align-items: left;
        position: absolute;
        background: grey;
        min-width: 200px;
        bottom: -10px;
        transform: translateY(100%);
        right: 0px;
        z-index: 1015;
        border-radius: 12px;
        background: #fff;
        box-shadow: 0px 4px 14px 0px rgba(87, 140, 186, 0.5);
        color: var(--Dark-Grey, #45505a);
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        gap: 13px;
        .cb-dropdown-options-search {
            border: 1px solid black;
            border-radius: 4px;
            padding: 4px;
            font-size: 14px;
        }
        .cb-dropdown-option {
            display: flex;
            gap: 10px;
            align-items: center;
        }
        .cb-dropdown-option.all-option {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            padding-bottom: 13px;
        }
        input[type='checkbox'] {
            accent-color: rgba(9, 87, 146, 1) !important;
            height: 18px;
            width: 18px;
        }
    }
}
