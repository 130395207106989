header .halloween-overlay {
    position: absolute;
    top: 0rem;
    left: 0rem;
    color: white;

    svg {
        position: absolute;
        color: white;
    }
}
