.floating-menu {
    display: inline-flex;
    width: 2rem;
    height: 2rem;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    visibility: visible;
    font-weight: bolder;
    position: relative;
    cursor: pointer;
    border: none;
    background: inherit;
}

.floating-menu-button {
    border: none;
    background: transparent;
}

.floating-menu:hover {
    background: rgb(0, 0, 0, 0.05);
}

.floating-menu-items {
    display: flex !important;
    flex-direction: column;
    padding: 1.5rem 1rem;
    justify-content: left;
    align-items: left;
    position: absolute;
    background: grey;
    width: 14rem;
    transform: translateY(100%);
    z-index: 1015;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 4px 14px 0px rgba(87, 140, 186, 0.5);
    color: var(--Dark-Grey, #45505a);
    font-weight: 400;
    line-height: normal;
    gap: 0.7rem;
    list-style: none;
    li {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 1rem;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
}
