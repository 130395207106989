@import 'src/css/repairDeckColors.scss';

.icon-wrapper {
    position: relative;
    display: inline-block;
    text-align: start !important;
}

.notification-dot {
    position: absolute;
    top: -0.4em;
    right: -0.9em;
    width: 1.4em;
    height: 1.4em;
    background-color: $red;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6em;
    line-height: 0.6em;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.notification-dot:hover {
    background-color: darken($red, 15%);
}

.warning-text {
    color: $orange;
    font-size: 0.7em;
    font-weight: bold;
    margin-left: 0.5em;
    font-style: italic;
}

.danger-text {
    color: $red;
    font-size: 0.7em;
    font-weight: bold;
    margin-left: 0.5em;
    font-style: italic;
}

.customer-action-button {
    transition: transform 0.2s ease-in-out, background-color 0.4s ease-in-out;
}

.customer-action-button.disabled {
    pointer-events: none;
    opacity: 0.65;
}
