.column-settings-modal {
    position: absolute;
    width: 400px;
    top: 180px;
    right: 15px;
    background-color: #fff;
    border-radius: 20px;
    z-index: 1021;

    .modal-body {
        padding: 1rem;
    }

    ul {
        max-height: 60vh;
        overflow-y: scroll;
        padding: 0;
        padding-left: 1rem;

        li {
            margin: 0;
        }
    }
}
