span.default-label {
    vertical-align: middle;
    font-size: 0.9rem;
}

span.default-pill {
    margin-left: 0.3em;
    background-color: white;
    font-weight: normal;
    color: black;
    font-size: 0.95em;
}
