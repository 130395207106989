.bulk-send-confirmation-modal-body {
    max-height: 300px;
    overflow: auto;
    .users-list {
        margin: 20px 15px;
    }
}
.company-users-table-checkboxes {
    height: 12.25px;
    width: 12.25px;
}
