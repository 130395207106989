.vehicle-published {
    color: white;
    background-color: limegreen;
    border-radius: 24px;
    margin-left: 2px;
    font-size: x-small;
    margin-bottom: 2px;
}

.book-label {
    max-width: 50vh;
    word-wrap: break-word;
}

.custom-textarea-scroll::-webkit-scrollbar {
    display: block;
    width: 5px;
}

.custom-textarea-scroll::-webkit-scrollbar-track {
    background: transparent;
}

.custom-textarea-scroll::-webkit-scrollbar-thumb {
    background-color: grey;
    border-right: none;
    border-left: none;
    border-radius: 24px;
    cursor: pointer !important;
}

.vehicle-publish-textarea {
    resize: none !important;
}

.custom-textarea-scroll::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 5px;
}

.custom-textarea-scroll::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 5px;
}
.custom-ddl-single-value {
    min-width: max-content;
}
.custom-ddl-single-value [class*='singleValue'] {
    overflow: visible;
}
