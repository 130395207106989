.search-bar {
    vertical-align: middle;

    .input-group {
        margin-bottom: 0;

        button {
            margin-left: -1px;
            border: 1px solid #ced4da;
            background-color: #fff;
            border-left-color: transparent;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            line-height: 18px;
            padding-bottom: 8.25px;

            &:hover {
                color: initial;
            }
        }
    }
}
