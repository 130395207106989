.sticky-top {
    position: sticky;
    top: 0;
}

.fixed-bottom {
    background-color: #fff;
    position: fixed;
    width: 100%;
    bottom: 0px;
    border-top: 2px solid #dee2e6;
}

.sticky-below-header {
    position: sticky;
    top: 56px;
    z-index: 10;
}

.sticky-below-action-bar {
    position: sticky;
    top: 109px;
}

.sticky-below-procedure-header {
    position: sticky;
    top: 168px;
}
