.modal-body {
    .vehicle-filter {
        section {
            display: flex;
            margin-top: 1rem;
            align-items: center;
            align-items: stretch;

            label:first-child {
                width: 80px;
                margin-left: 0;
            }

            label {
                background-color: lightgrey;
                padding: 0.6rem 0.8rem 0 0.8rem;
                border-radius: 0.3rem;
                margin-left: 1rem;
            }

            .filter-select {
                flex: 1;
                margin-left: 0.5rem;
            }

            .year-operator-select {
                margin-left: 0.5rem;
                width: 100px;
            }

            input {
                flex: 1;
                margin-left: 0.5rem;
            }
        }
    }
}
