@keyframes moveDown {
    from {
        top: -20rem;
    }

    to {
        top: 3rem;
    }
}

.mapping-rules-run-indicator {
    position: relative;
    overflow: hidden;
    padding: 1rem 1rem;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;

    &::before {
        content: attr(data-bg-content);
        position: absolute;
        top: 0;
        left: -0.1rem;
        font-family: 'Courier New', Courier, monospace;
        opacity: 0.5;
        width: 10rem;
        line-height: 0.8rem;
        animation: 5s infinite linear moveDown;
    }
}
