@import '~/node_modules/bootstrap/scss/_modal';

@keyframes fadeProgress {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

html {
    font-size: 14px;
}

table {
    background-color: transparent;
}

button.close {
    @extend .btn-close;

    span {
        opacity: 0;
    }
}

.toast-container {
    top: 50px;
    left: calc(50% - 175px);

    .cool-toast-header.toast-header {
        button.close {
            top: 6px;
            right: 10px;
        }
    }
}

.navigation-tile {
    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.open-modal-fast {
    overflow: auto;
    padding-right: initial !important;
}

.navbar-brand {
    img {
        height: 40px;
        margin-bottom: -7px;
        margin-top: -10px;
    }
}

.breadcrumb {
    padding-left: 1rem;
}

.container-main {
    height: calc(100vh - 1.5 * 1.25rem - 2 * 0.3125rem - 2 * 0.5rem);
}

.toast {
    width: 25rem;

    .toast-header .pin {
        display: none;
    }

    &.pinned .toast-header .pin {
        display: block;
        position: absolute;
        top: -11px;
        left: 3px;
        color: #212529;
        font-size: 1.3rem;
        transform: rotate(20deg);
    }

    .toast-header {
        position: relative;
        background-color: transparent;
        color: inherit;
        border-bottom: 0;
        padding: 0;

        strong {
            padding: 0.75rem;
            padding-bottom: 0;
            margin-bottom: -0.5rem;
        }

        .close {
            position: absolute;
            top: 0;
            right: 5px;
        }
    }

    .toast-body {
        padding-right: 1rem;

        ul {
            padding-left: 1rem;
            margin-bottom: 0;
        }
    }
}

.modal-dialog {
    &.sticky-footer {
        .modal-footer {
            position: sticky;
            bottom: 0;
            background-color: #fff;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
        }
    }
}

.top-menu.dropdown {
    .dropdown-toggle {
        padding: 5px 0;
        border: 0;
        line-height: 1;
        margin-top: -2px;
        color: #fff;
    }

    .dropdown-menu {
        top: calc(100% + 12px);
    }
}

.table-cell-select {
    max-width: 200px;
    line-height: 1.2;

    [class*='singleValue'] {
        position: static;
        white-space: normal;
        text-overflow: clip;
        overflow: visible;
        transform: none;
    }

    [class*='singleValue'] ~ div {
        position: absolute;
        top: 0;
    }

    [class*='indicatorContainer'] {
        padding: 0;
    }
}

.table-filters {
    width: 100%;
    border: 1px lightgray solid;
    border-radius: 7px;
    padding: 2px;

    .filter-symbol {
        font-size: 1.5rem;
        margin-top: 14px;
    }

    .control-btn {
        button {
            height: 59px;
            font-size: 1.5rem;
            border-radius: 5px;
        }
    }

    .filters-list {
        padding: 0;
        width: 100%;
        overflow-x: auto;
        margin-bottom: 0;
        .filter {
            position: relative;
            list-style-type: none;
            border-radius: 5px;
            color: #fff;
            padding: 0.6rem;
            padding-right: 75px;
            max-width: 400px;
            height: 59px;
            cursor: pointer;

            .filter-name {
                font-weight: bold;
                min-width: 100px;
            }

            .filter-operation {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 70px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                text-align: center;

                &::before {
                    content: '';
                    display: inline-block;
                    height: 100%;
                    width: 0;
                    background-color: red;
                    vertical-align: middle;
                }

                span {
                    display: inline-block;
                    vertical-align: middle;
                    line-height: 1.1rem;
                }
            }

            .filter-value {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .filter-remove {
                position: absolute;
                top: 0;
                right: 0;
                padding: 0 5px;
                border-top-right-radius: 5px;
                border-bottom-left-radius: 5px;
                background-color: #dc3545;
                cursor: pointer;

                &:hover {
                    background-color: darken(#dc3545, 10%);
                }
            }

            &.bg-primary:hover {
                background-color: darken(#095792, 10%) !important;
            }

            &.bg-success:hover {
                background-color: darken(#28a745, 10%) !important;
            }

            &.filter-app-only {
                cursor: not-allowed;
            }
        }
    }
}

.progress.fade-progress {
    animation-name: fadeProgress;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

.fieldset {
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-width: 1px;
    border-radius: 4px;
    border-style: solid;
    border-color: rgb(192, 192, 192);
    border-image: initial;
}

.legend {
    display: block;
    padding-inline-start: 2px;
    padding-inline-end: 2px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    width: unset;
    font-size: 1rem;
    line-height: 1.2;
}

.table {
    border-collapse: separate;
    border-spacing: 0;

    &.sticky-header {
        position: relative;

        thead {
            th {
                background-color: #fff;
                position: sticky;
                top: 48px;
                z-index: 1;
            }
        }
    }
    .no-rows-found {
        text-align: center;
        height: 65vh;
    }
}

.flex-basis-50 {
    flex-basis: 50%;
}

.bg-red-200 {
    background-color: $red-200;
}

.bg-red-400 {
    background-color: $red-400;
}

.bg-gray-500 {
    background-color: $gray-500;
}

.bg-yellow-500 {
    background-color: $yellow-500;
}
