.gsearch-dropdown {
    font-size: 12px !important;
    max-height: 75dvh;
    overflow: auto;
}

.gsearch-dropdown::-webkit-scrollbar {
    display: none;
}

.gsearch-dropdown:hover::-webkit-scrollbar {
    display: flex;
    width: 3px !important;
    position: absolute;
}

.gsearch-dropdown:hover::-webkit-scrollbar-thumb {
    background-color: var(--bs-gray-500);
}

.gsearch-dropdown .icon-rounded {
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    border-radius: 50%;
}

.fs-12px {
    font-size: 12px !important;
}

.fs-13px {
    font-size: 13px !important;
}

.gsearch-scope-dropdown-menu {
    z-index: 9999 !important;
}

.gsearch-bar .dropdown-toggle::after {
    border: none !important;
}

.gsearch-bar .btn-clear {
    border-radius: var(--bs-btn-border-radius) !important;
}

.text-placeholder {
    color: #6c757d !important;
    opacity: 1;
}

.gsearch-bar .gsearch-text {
    border-color: unset !important;
    box-shadow: none !important;
}

.gsearch-bar:focus-within {
    border-color: #84abc9 !important;
    box-shadow: 0 0 0 0.2rem rgba(9, 87, 146, 0.25);
}
