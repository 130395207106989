.ingestion-process-table td {
    vertical-align: middle;
}

.ingestion-process-table th#checkboxes {
    width: 10rem;
}

.ingestion-process {
    width: 15rem;
    text-align: center; /* Center text content */
}

.ingestion-process-header {
    text-align: center;
    width: 20rem;
}

.gmIngestionProcessHeader {
    text-align: center;
    width: 15rem;
}

.gMVehicleListHeader {
    width: 25%;
}

.fordModelNameHeader,
.gMPubNameHeader {
    width: 20%;
}

.fordModelIdHeader,
.gMPubsyskeyHeader,
.hyundaiModelCodeHeader,
.genesisModelCodeHeader {
    width: 10%;
}

.hyundaiModelNameHeader,
.genesisModelNameHeader {
    width: 15%;
}

.ingestion-process-btngroup {
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 0;

    .btn.btn-outline-secondary {
        border-color: #ced4da !important;
    }
    .status-count {
        margin: 10px;
        border: 1.5px solid #cfd4da;
        padding: 0px 8px;
        box-sizing: border-box;
    }
    .btn > .status-count {
        background-color: white !important;
        color: rgb(108, 117, 125);
    }
}
