.bulk-publish-status-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;

    .progress {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}
