@import 'src/css/repairDeckColors.scss';

.columns {
    margin-top: 1em;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
    .column {
        width: 180px;
        margin-top: 0.5em;
        div {
            padding: 1em;
            text-align: center;
            height: 55px;
            span {
                margin-right: 0.4em;
            }
        }
    }
}

.box-blue-100 {
    background-color: $blue-100;
    color: black;
}
.box-blue-200 {
    background-color: $blue-200;
    color: black;
}
.box-blue-300 {
    background-color: $blue-300;
    color: black;
}
.box-blue-400 {
    background-color: $blue-400;
    color: white;
}
.box-blue-500 {
    background-color: $blue-500;
    color: white;
}
.box-blue-600 {
    background-color: $blue-600;
    color: white;
}
.box-blue-700 {
    background-color: $blue-700;
    color: white;
}
.box-blue-800 {
    background-color: $blue-800;
    color: white;
}
.box-blue-900 {
    background-color: $blue-900;
    color: white;
}
.box-blue-100 span::after {
    content: ' #{$blue-100}';
}
.box-blue-200 span::after {
    content: ' #{$blue-200}';
}
.box-blue-300 span::after {
    content: ' #{$blue-300}';
}
.box-blue-400 span::after {
    content: ' #{$blue-400}';
}
.box-blue-500 span::after {
    content: ' #{$blue-500}';
}
.box-blue-600 span::after {
    content: ' #{$blue-600}';
}
.box-blue-700 span::after {
    content: ' #{$blue-700}';
}
.box-blue-800 span::after {
    content: ' #{$blue-800}';
}
.box-blue-900 span::after {
    content: ' #{$blue-900}';
}

.box-indigo-100 {
    background-color: $indigo-100;
    color: black;
}
.box-indigo-200 {
    background-color: $indigo-200;
    color: black;
}
.box-indigo-300 {
    background-color: $indigo-300;
    color: black;
}
.box-indigo-400 {
    background-color: $indigo-400;
    color: white;
}
.box-indigo-500 {
    background-color: $indigo-500;
    color: white;
}
.box-indigo-600 {
    background-color: $indigo-600;
    color: white;
}
.box-indigo-700 {
    background-color: $indigo-700;
    color: white;
}
.box-indigo-800 {
    background-color: $indigo-800;
    color: white;
}
.box-indigo-900 {
    background-color: $indigo-900;
    color: white;
}
.box-indigo-100 span::after {
    content: ' #{$indigo-100}';
}
.box-indigo-200 span::after {
    content: ' #{$indigo-200}';
}
.box-indigo-300 span::after {
    content: ' #{$indigo-300}';
}
.box-indigo-400 span::after {
    content: ' #{$indigo-400}';
}
.box-indigo-500 span::after {
    content: ' #{$indigo-500}';
}
.box-indigo-600 span::after {
    content: ' #{$indigo-600}';
}
.box-indigo-700 span::after {
    content: ' #{$indigo-700}';
}
.box-indigo-800 span::after {
    content: ' #{$indigo-800}';
}
.box-indigo-900 span::after {
    content: ' #{$indigo-900}';
}

.box-purple-100 {
    background-color: $purple-100;
    color: black;
}
.box-purple-200 {
    background-color: $purple-200;
    color: black;
}
.box-purple-300 {
    background-color: $purple-300;
    color: black;
}
.box-purple-400 {
    background-color: $purple-400;
    color: white;
}
.box-purple-500 {
    background-color: $purple-500;
    color: white;
}
.box-purple-600 {
    background-color: $purple-600;
    color: white;
}
.box-purple-700 {
    background-color: $purple-700;
    color: white;
}
.box-purple-800 {
    background-color: $purple-800;
    color: white;
}
.box-purple-900 {
    background-color: $purple-900;
    color: white;
}
.box-purple-100 span::after {
    content: ' #{$purple-100}';
}
.box-purple-200 span::after {
    content: ' #{$purple-200}';
}
.box-purple-300 span::after {
    content: ' #{$purple-300}';
}
.box-purple-400 span::after {
    content: ' #{$purple-400}';
}
.box-purple-500 span::after {
    content: ' #{$purple-500}';
}
.box-purple-600 span::after {
    content: ' #{$purple-600}';
}
.box-purple-700 span::after {
    content: ' #{$purple-700}';
}
.box-purple-800 span::after {
    content: ' #{$purple-800}';
}
.box-purple-900 span::after {
    content: ' #{$purple-900}';
}

.box-pink-100 {
    background-color: $pink-100;
    color: black;
}
.box-pink-200 {
    background-color: $pink-200;
    color: black;
}
.box-pink-300 {
    background-color: $pink-300;
    color: black;
}
.box-pink-400 {
    background-color: $pink-400;
    color: white;
}
.box-pink-500 {
    background-color: $pink-500;
    color: white;
}
.box-pink-600 {
    background-color: $pink-600;
    color: white;
}
.box-pink-700 {
    background-color: $pink-700;
    color: white;
}
.box-pink-800 {
    background-color: $pink-800;
    color: white;
}
.box-pink-900 {
    background-color: $pink-900;
    color: white;
}
.box-pink-100 span::after {
    content: ' #{$pink-100}';
}
.box-pink-200 span::after {
    content: ' #{$pink-200}';
}
.box-pink-300 span::after {
    content: ' #{$pink-300}';
}
.box-pink-400 span::after {
    content: ' #{$pink-400}';
}
.box-pink-500 span::after {
    content: ' #{$pink-500}';
}
.box-pink-600 span::after {
    content: ' #{$pink-600}';
}
.box-pink-700 span::after {
    content: ' #{$pink-700}';
}
.box-pink-800 span::after {
    content: ' #{$pink-800}';
}
.box-pink-900 span::after {
    content: ' #{$pink-900}';
}

.box-red-100 {
    background-color: $red-100;
    color: black;
}
.box-red-200 {
    background-color: $red-200;
    color: black;
}
.box-red-300 {
    background-color: $red-300;
    color: black;
}
.box-red-400 {
    background-color: $red-400;
    color: white;
}
.box-red-500 {
    background-color: $red-500;
    color: white;
}
.box-red-600 {
    background-color: $red-600;
    color: white;
}
.box-red-700 {
    background-color: $red-700;
    color: white;
}
.box-red-800 {
    background-color: $red-800;
    color: white;
}
.box-red-900 {
    background-color: $red-900;
    color: white;
}
.box-red-100 span::after {
    content: ' #{$red-100}';
}
.box-red-200 span::after {
    content: ' #{$red-200}';
}
.box-red-300 span::after {
    content: ' #{$red-300}';
}
.box-red-400 span::after {
    content: ' #{$red-400}';
}
.box-red-500 span::after {
    content: ' #{$red-500}';
}
.box-red-600 span::after {
    content: ' #{$red-600}';
}
.box-red-700 span::after {
    content: ' #{$red-700}';
}
.box-red-800 span::after {
    content: ' #{$red-800}';
}
.box-red-900 span::after {
    content: ' #{$red-900}';
}

.box-orange-100 {
    background-color: $orange-100;
    color: black;
}
.box-orange-200 {
    background-color: $orange-200;
    color: black;
}
.box-orange-300 {
    background-color: $orange-300;
    color: black;
}
.box-orange-400 {
    background-color: $orange-400;
    color: black;
}
.box-orange-500 {
    background-color: $orange-500;
    color: white;
}
.box-orange-600 {
    background-color: $orange-600;
    color: white;
}
.box-orange-700 {
    background-color: $orange-700;
    color: white;
}
.box-orange-800 {
    background-color: $orange-800;
    color: white;
}
.box-orange-900 {
    background-color: $orange-900;
    color: white;
}
.box-orange-100 span::after {
    content: ' #{$orange-100}';
}
.box-orange-200 span::after {
    content: ' #{$orange-200}';
}
.box-orange-300 span::after {
    content: ' #{$orange-300}';
}
.box-orange-400 span::after {
    content: ' #{$orange-400}';
}
.box-orange-500 span::after {
    content: ' #{$orange-500}';
}
.box-orange-600 span::after {
    content: ' #{$orange-600}';
}
.box-orange-700 span::after {
    content: ' #{$orange-700}';
}
.box-orange-800 span::after {
    content: ' #{$orange-800}';
}
.box-orange-900 span::after {
    content: ' #{$orange-900}';
}

.box-yellow-100 {
    background-color: $yellow-100;
    color: black;
}
.box-yellow-200 {
    background-color: $yellow-200;
    color: black;
}
.box-yellow-300 {
    background-color: $yellow-300;
    color: black;
}
.box-yellow-400 {
    background-color: $yellow-400;
    color: black;
}
.box-yellow-500 {
    background-color: $yellow-500;
    color: black;
}
.box-yellow-600 {
    background-color: $yellow-600;
    color: white;
}
.box-yellow-700 {
    background-color: $yellow-700;
    color: white;
}
.box-yellow-800 {
    background-color: $yellow-800;
    color: white;
}
.box-yellow-900 {
    background-color: $yellow-900;
    color: white;
}
.box-yellow-100 span::after {
    content: ' #{$yellow-100}';
}
.box-yellow-200 span::after {
    content: ' #{$yellow-200}';
}
.box-yellow-300 span::after {
    content: ' #{$yellow-300}';
}
.box-yellow-400 span::after {
    content: ' #{$yellow-400}';
}
.box-yellow-500 span::after {
    content: ' #{$yellow-500}';
}
.box-yellow-600 span::after {
    content: ' #{$yellow-600}';
}
.box-yellow-700 span::after {
    content: ' #{$yellow-700}';
}
.box-yellow-800 span::after {
    content: ' #{$yellow-800}';
}
.box-yellow-900 span::after {
    content: ' #{$yellow-900}';
}

.box-green-100 {
    background-color: $green-100;
    color: black;
}
.box-green-200 {
    background-color: $green-200;
    color: black;
}
.box-green-300 {
    background-color: $green-300;
    color: black;
}
.box-green-400 {
    background-color: $green-400;
    color: white;
}
.box-green-500 {
    background-color: $green-500;
    color: white;
}
.box-green-600 {
    background-color: $green-600;
    color: white;
}
.box-green-700 {
    background-color: $green-700;
    color: white;
}
.box-green-800 {
    background-color: $green-800;
    color: white;
}
.box-green-900 {
    background-color: $green-900;
    color: white;
}
.box-green-100 span::after {
    content: ' #{$green-100}';
}
.box-green-200 span::after {
    content: ' #{$green-200}';
}
.box-green-300 span::after {
    content: ' #{$green-300}';
}
.box-green-400 span::after {
    content: ' #{$green-400}';
}
.box-green-500 span::after {
    content: ' #{$green-500}';
}
.box-green-600 span::after {
    content: ' #{$green-600}';
}
.box-green-700 span::after {
    content: ' #{$green-700}';
}
.box-green-800 span::after {
    content: ' #{$green-800}';
}
.box-green-900 span::after {
    content: ' #{$green-900}';
}

.box-teal-100 {
    background-color: $teal-100;
    color: black;
}
.box-teal-200 {
    background-color: $teal-200;
    color: black;
}
.box-teal-300 {
    background-color: $teal-300;
    color: black;
}
.box-teal-400 {
    background-color: $teal-400;
    color: black;
}
.box-teal-500 {
    background-color: $teal-500;
    color: black;
}
.box-teal-600 {
    background-color: $teal-600;
    color: white;
}
.box-teal-700 {
    background-color: $teal-700;
    color: white;
}
.box-teal-800 {
    background-color: $teal-800;
    color: white;
}
.box-teal-900 {
    background-color: $teal-900;
    color: white;
}
.box-teal-100 span::after {
    content: ' #{$teal-100}';
}
.box-teal-200 span::after {
    content: ' #{$teal-200}';
}
.box-teal-300 span::after {
    content: ' #{$teal-300}';
}
.box-teal-400 span::after {
    content: ' #{$teal-400}';
}
.box-teal-500 span::after {
    content: ' #{$teal-500}';
}
.box-teal-600 span::after {
    content: ' #{$teal-600}';
}
.box-teal-700 span::after {
    content: ' #{$teal-700}';
}
.box-teal-800 span::after {
    content: ' #{$teal-800}';
}
.box-teal-900 span::after {
    content: ' #{$teal-900}';
}

.box-cyan-100 {
    background-color: $cyan-100;
    color: black;
}
.box-cyan-200 {
    background-color: $cyan-200;
    color: black;
}
.box-cyan-300 {
    background-color: $cyan-300;
    color: black;
}
.box-cyan-400 {
    background-color: $cyan-400;
    color: black;
}
.box-cyan-500 {
    background-color: $cyan-500;
    color: black;
}
.box-cyan-600 {
    background-color: $cyan-600;
    color: white;
}
.box-cyan-700 {
    background-color: $cyan-700;
    color: white;
}
.box-cyan-800 {
    background-color: $cyan-800;
    color: white;
}
.box-cyan-900 {
    background-color: $cyan-900;
    color: white;
}
.box-cyan-100 span::after {
    content: ' #{$cyan-100}';
}
.box-cyan-200 span::after {
    content: ' #{$cyan-200}';
}
.box-cyan-300 span::after {
    content: ' #{$cyan-300}';
}
.box-cyan-400 span::after {
    content: ' #{$cyan-400}';
}
.box-cyan-500 span::after {
    content: ' #{$cyan-500}';
}
.box-cyan-600 span::after {
    content: ' #{$cyan-600}';
}
.box-cyan-700 span::after {
    content: ' #{$cyan-700}';
}
.box-cyan-800 span::after {
    content: ' #{$cyan-800}';
}
.box-cyan-900 span::after {
    content: ' #{$cyan-900}';
}
.box-gray-500 {
    background-color: $gray-500;
    color: black;
}
.box-gray-500 span::after {
    content: ' #{$gray-500}';
}
