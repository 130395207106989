@import 'src/css/repairDeckColors.scss';

td.column-procedure-title button.btn {
    padding: 0;
    border: 0;
}
.new-tab-link-icon {
    border: 1;
    border-radius: 3px;
    height: 1rem;
    padding: 2px 4px;
    margin-left: 1px;
}
.new-tab-link-icon:hover {
    color: white;
    background-color: $blue-600;
}
