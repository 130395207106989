.manage-customers-layout-container {
    .nav-bar {
        display: flex;
        justify-content: space-between;
    }
    .searchbox-container {
        .col-2 {
            width: unset;
        }
    }
    .manage-customers-nav {
        top: 50px;
        background: white;
        z-index: 1020;
        height: 75px;
    }
    thead {
        position: sticky;
        top: 125px;
        background: white;
        z-index: 1019;
        text-transform: uppercase;
    }
    tr:hover {
        background: rgba(0, 0, 0, 0.05) !important;
        .floating-menu {
            visibility: visible;
        }
    }
    tr {
        height: 45px;
        vertical-align: middle;
    }
    .locations-cell {
        span {
            margin-right: 0.2rem;
        }
        .floating-menu {
            visibility: visible;
        }
    }
    .floating-menu {
        visibility: hidden;
        .floating-menu-items {
            gap: 1rem;
            button:disabled {
                pointer-events: none;
                opacity: 0.65;
                cursor: not-allowed;
            }
            .action {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                color: var(--Dark-Grey, #45505a);
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                cursor: inherit;
                pointer-events: inherit;
                .action-icon {
                    font-size: 1.2rem;
                }
            }
        }
    }
}
