.procedure-content {
    width: 100%;
    height: 500px;
}

div.modal-max-height {
    max-width: 95vw;
    height: 100%;
    margin: 2vw auto;

    .procedure-content {
        display: block;
        background: #fff;
        border: none;
        height: 82vh;
    }
}
