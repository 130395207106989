.card.property-column {
    min-width: 9rem;
    min-height: 20rem;
}

.loading-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    border-radius: 0.25rem;
    opacity: 0.9;
    padding-top: 10rem;
}

i.empty-value {
    color: #906;
}

i.null-value {
    color: #998;
}
