$gray: #8a8a8a;
$green: #1ab415;
$red: #be1021;

.custom-switch .custom-control-label {
    cursor: pointer;
}

.custom-switch .custom-control-label::before {
    background-color: white !important;
}

.custom-switch input:disabled + .custom-control-label {
    cursor: default;

    &::before,
    &::after {
        opacity: 0.5;
    }
}

.custom-switch .custom-control-label::after {
    background-color: $red !important;
}

.custom-control-input:checked ~ .custom-control-label::after {
    background-color: $green !important;
    border-color: $gray !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: $gray !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
    border-color: $gray !important;
    box-shadow: none !important;
}
