$blue: #0d6efd;
$blue-300: mix(white, $blue, 40);
$yellow-600: #cc9a06;

span.rule-label {
    background-color: $blue-300;
    font-weight: bold;
    color: white;
    font-size: 0.95em;
}

span.rule-condition-pill {
    margin-left: 0.3em;
    background-color: white;
    font-weight: normal;
    color: $yellow-600;
    font-size: 0.95em;
}

span.rule-pill {
    margin-left: 0.3em;
    background-color: white;
    font-weight: normal;
    color: black;
    font-size: 0.95em;
    max-width: 40rem;
    text-overflow: ellipsis;
    overflow: clip;
}

span.rule-pill i.empty-value {
    color: #906;
}
