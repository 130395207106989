@use '_base.scss';

.oemiq-select,
.oemiq-select:focus-within,
.oemiq-select:focus-visible,
.oemiq-select:focus {
    outline: none !important;
    border: 0px;
    color: $secondary_blue;
}
